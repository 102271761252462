<template>
   <div class="container-fluid">
      <ul>
         <td colspan="7">
            <div class="text-center p-5">
               <h4>Drop files anywhere to upload<br />or</h4>
               <label for="file" class="btn btn-lg btn-primary">Select Files</label>
            </div>
         </td>
      </ul>
      <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
         <h3>Drop files to upload</h3>
      </div>
      <div class="example-btn">
         <file-upload class="btn btn-primary" :multiple="true" :drop="true"
            :drop-directory="true" v-model="files" @input-file="updateValue" @input-filter="inputFilter" ref="upload">
   <p>{{savedFiles}}</p>
   <p>{{files}}</p>
         </file-upload>
      </div>
      <draggable :list="savedFiles" :disabled="!enabled" class="list-group" ghost-class="ghost" :move="checkMove"
         @start="dragging = true" @end="dragging = false">
         <div class="list-group-item" v-for="(element, index) in savedFiles" :key="element.position">
            <img class="product-img" :src="element.thumb" />
            <p>{{ index }}</p>
            <span  @click.prevent="remove(element.id)" v-if="element.isNew" class="badge mr-1 badge-primary">Delete (new)</span>
            <span v-if="element.isNew" class="badge mr-1 badge-primary">New</span>
            <span @click="deleteImage(element.magento_id)" v-if="!element.isNew" class="badge mr-1 badge-primary">Delete</span>
         </div>
      </draggable>
      <button type="button" @click="onUpload()" class="btn mt-2 mr-1 btn-primary">Primary</button>
   </div>
</template>
<script>
import { mapGetters } from 'vuex'
import draggable from "vuedraggable";
export default {
   name: 'BlankPage',
   components: {
      draggable
   },
   data() {
      return {
         enabled: true,
         list: [
            { name: "John", img: "immg", id: 0 },
            { name: "Joao", id: 1 },
            { name: "Jean", id: 2 }
         ],
         upload: '',
         files: [],
         dragging: false,
         accept: 'image/png,image/gif,image/jpeg,image/webp',
         extensions: 'gif,jpg,jpeg,png,webp',
         minSize: 1024,
         size: 1024 * 1024 * 10,
         multiple: true,
         directory: false,
         thread: 3,
         drop: true,
         addIndex: false,
         isSaving: false,
         savedFiles: []

      };
   },
   computed: {
      productData(){
         return this.$store.state.products.product
      },
      ...mapGetters({

      }),
      getImages() {
         return this.$store.state.products.product.media_gallery_entries
      },
      urlPath() {
         return this.$store.getters['getUser'].image_base_url
      },
   },
   methods: {
      checkMove: function (e) {
         window.console.log("Future index: " + e.draggedContext.futureIndex);
      },
      remove(file) {
      this.$refs.upload.remove(file)
      const arr = this.savedFiles
      const indexOfObject = arr.findIndex(object => {
  return object.id === file;
});
arr.splice(indexOfObject, 1);
      },
      updateValue(value) {
      console.log(value.blob)
      var thumb = value.blob
      this.savedFiles.push({ thumb: thumb, file: value.blob, name: value.name, isNew: true, type: value.type, blob: value.blob, id: value.id });
      // this.sendNewImage(value)
      },
      deleteImage(id){
         this.$store.dispatch('products/deleteMedia', {
         sku: this.productData.sku,
         id: id
       });
      },
      inputFilter(newFile, oldFile) {
         console.log(newFile)
      if (newFile && newFile.error === '' && newFile.file && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.blob = '';
        let URL = window.URL || window.webkitURL;
        if (URL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }
        newFile.thumb = '';
        if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
          newFile.thumb = newFile.blob;
        }
        if (
          newFile &&
          newFile.error === '' &&
          newFile.type.substr(0, 6) === 'image/' &&
          newFile.blob &&
          (!oldFile || newFile.blob !== oldFile.blob)
        ) {
          newFile.error = 'image parsing';
          let img = new Image();
          img.src = newFile.blob;
        }
      }
         
      },
   //    async sendNewImage (file){
   //    try {
   //      this.isSaving = true;
   //      let sendingFiles = [];
   //            const fileBase64 = await new Promise((resolve) => {
   //             fetch(file.blob)
   //    .then((res) => res.blob())
   //    .then((blobData) => {
   //    const reader = new FileReader();
   //    reader.readAsDataURL(blobData);
   //    reader.onloadend = () => {
   //      resolve(reader.result);
   //    };
   //  });
   //            });

   //            sendingFiles.push({
   //              name: file.name,
   //              action: 'new',
   //              data: {
   //                ...file,
   //                fileBase64,
   //              },
   //            });
            
      
      
   //        console.log(sendingFiles)
   //      // const imageIds = productData.value.media_gallery_entries.map(image => image.id);
   //      const result = await this.$store.dispatch('products/updateProductMedia', {
   //        files: sendingFiles,
   //        // imageIds,
   //      });
   //      console.log(result, 'result');


   //      this.isSaving = false;
   //    } catch (err) {
   //      this.isSaving = false;
   //    }
   //  },
      async onUpload (){
      try {
        let base64Files = [...this.savedFiles];
        this.isSaving = true;
        let sendingFiles = [];

        await Promise.all(
          base64Files.map(async (file, index) => {
            file['position'] = index;
            if (file.isNew) {
              const fileBase64 = await new Promise((resolve) => {
               fetch(file.blob)
      .then((res) => res.blob())
      .then((blobData) => {
      const reader = new FileReader();
      reader.readAsDataURL(blobData);
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
              });

              sendingFiles.push({
                name: file.name,
                action: 'new',
                data: {
                  ...file,
                  fileBase64,
                },
              });
            } else {
              sendingFiles.push({
                name: file.name,
                action: 'keep',
                data: {
                  ...file,
                  fileBase64: null,
                },
              });
            }
          }),
        );
          console.log(sendingFiles)
        // const imageIds = productData.value.media_gallery_entries.map(image => image.id);
        const result = await this.$store.dispatch('products/updateProductMedia', {
          files: sendingFiles,
          // imageIds,
        });
        console.log(result, 'result');


        this.isSaving = false;
      } catch (err) {
        this.isSaving = false;
      }
    }
   },
   mounted() {
      this.savedFiles = [];

if (this.$store.state.products.product.media_gallery_entries) {
  this.isSaving = true;

  this.savedFiles = this.$store.state.products.product.media_gallery_entries.map((image) => {
    const urlAry = image.file.split('/');
    const fileName = urlAry[urlAry.length - 1].split('.')[0];
    const fileUrl = image.file;

    return {
      thumb: this.$store.getters['getUser'].image_base_url + fileUrl,
      name: fileName,
      type: image.media_type,
      size: 0,
      position: image.position,
      magento_id: image.id,
    };
  });

  this.savedFiles.sort((a, b) => a.position - b.position);

  this.savedFiles.map((file) => {
    this.upload.value.add(file);
  });

  this.isSaving.value = false;
}
    },
}
</script>
<style scoped lang="scss">
.product-img {
   width: 200px;
   height: auto;
   border-radius: 8px;
   overflow: hidden;
}

.list-group {
   display: grid;
   width: 300px;
   grid-template-columns: 1fr 1fr 1fr;
}

.list-group-item {
   border: none;
}
</style>